import { Container, Grid } from '@mui/material'
import React from 'react'
import './styles.css'

function CryptoCurrency({ coins }) {

    return (
        <>
            <div className='cryptoWrapper'>
                <Container fluid >
                    <Grid container fluid >
                        {
                            coins.slice(0, 4).map((item) => (

                                <Grid lg={3} md={6} sm={6} xs={12} className="cryptoDetailWrapper" key={item?.node?.id}>
                                    <div className='oneByOne'>
                                        <div className='cryptoHeaderContainer'>
                                            <div className='cryptoLogoName'>
                                                <div
                                                    className='cryptoLogo'
                                                    style={{ backgroundImage: `url(${item.image})` }}
                                                >
                                                </div>
                                                <div>
                                                    <div>
                                                        <p className='cryptoName'>{item.name}/<span>{item.symbol}</span></p>
                                                    </div>
                                                    <div>
                                                        <p className={item.price_change_percentage_24h < 0 ? 'negChange' : 'posChange'}>{item.price_change_percentage_24h.toFixed(2)}%</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div style={{}}>
                                                    <p className='cryptoCurrency'>${item.current_price}</p>
                                                </div>
                                                <div>
                                                    <p className='volumeValue'>{item.total_volume}$</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Grid>

                            ))
                        }

                    </Grid>
                </Container>
            </div>
        </>
    )
}

export default CryptoCurrency