import React from 'react'
import './styles.css'
import MobileApp from '../../sections/mobileapp';
import Features from '../../sections/features';
import CryptoCurrency from '../../components/cryptocurrency'
import Body from '../../sections/body';
import CoinsImages from '../../sections/CoinsImages';

function HomePage({ coins }) {

    return (
        <div>
            <Body coins={coins} />
            <CryptoCurrency coins={coins} />
            <MobileApp />
            <Features />
            <CoinsImages coins={coins} />
        </div>
    )
}

export default HomePage