import { Grid, Button } from '@mui/material'
import React, { useState } from 'react'
import { BsFillArrowLeftCircleFill } from 'react-icons/bs'
import { Link } from 'react-router-dom'
import SigninFields from './signinFields'
import './styles.css'

function Signin() {
    const [errormessage, setErrormessage] = useState('')
    return (
        <>
            <Grid container fluid >
                <Grid lg={8} md={8} sm={8} xs={12} className="SigninWrapper">
                    <div className='SigninContainer'>
                        <div class="SigninCard">
                            <div className='ArrowBack'>
                                <Link to="/" style={{ color: 'white', textDecoration: 'none' }}>
                                    <BsFillArrowLeftCircleFill size={25} style={{ color: '#a4a4a4' }} />
                                </Link>
                            </div>
                            <div className='SigninContainerChildOne'>
                                <p className='SigninSignupTitle'>
                                    Join to build the future
                                </p>
                                <div className='SigninTitleContainer'>
                                    <p className='SigninSignupTitle'>
                                        Sign in
                                    </p>
                                </div>
                                {
                                    errormessage ?
                                        <p style={{ color: 'red' }}>{errormessage}</p>
                                        :
                                        null
                                }
                                <SigninFields setErrormessage={setErrormessage} />
                            </div>
                        </div>
                    </div>
                </Grid>
                <Grid lg={4} md={4} sm={4} xs={12} className="SigninWrapper">
                    <div className='SigninContainer'>
                        <div className='SigninCard'>
                            <div>
                                <p className='SigninSignupTitle'>
                                    New User ?
                                </p>
                            </div>
                            <p className='SigninSignup'> Sign up and discover a great amount of new opportunities
                                {" "}
                            </p>
                            <div className="divSubmitButton">
                                <Link to="/signup" style={{ textDecoration: 'none', }}>
                                    <Button
                                        variant="outlined"
                                        className="submitButton"
                                    >
                                        Sign up
                                    </Button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </>
    )
}

export default Signin