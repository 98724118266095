import { Button, Container, Grid, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import './styles.css'
import Cookies from 'universal-cookie'
import { useFormik } from 'formik'
import { reduce, createTransaction, withdrawEmail, getUserById } from '../../api'
import { Box } from '@mui/system'


function WithDraw() {
    const cookie = new Cookies()
    const [message, setMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    const [amountValue, setAmountValue] = useState("");
    const [buttonClicked, setButtonClicked] = useState(false);

    const [user, setUser] = useState([])


    useEffect(
        () => {
            cookie.get('id') &&
                getUserById(cookie.get('id')).then(res => {
                    setUser(res.data)

                }).catch(error => console.log(error));
        },
        [])

    const handleAmountChange = (e) => {
        setAmountValue(e.target.value);
    };

    const formik = useFormik({
        initialValues: {
            wallet: '',
            receiver: '',
            amount: ''
        },
        onSubmit: () => {
            const amount = amountValue;
            const receiver = formik.values.receiver;

            const reload = () => {
                window.location.reload()
            }


            const data = {
                sender: user._id,
                receiver: formik.values.receiver,
                amount: amountValue,
            }

            if ((receiver === "" && amount === "") || amount <= 0 || receiver === "" || amount === "") {
                setErrorMessage("ERROR : Empty Fields or Negative Value!!");

            } else {
                if (amount <= user?.balance) {
                    if (!buttonClicked) {
                        setButtonClicked(true)
                        const func = createTransaction(data)
                        func.then((response) => {
                            if (response.success) {
                                reduce(user.wallet, amount)
                                setMessage("Your order has been processed,awaiting approval!! Kindly check transaction page.");
                                // withdrawEmail(user.email, amount);
                                setTimeout(reload, 3000);
                            } else {
                                alert("error")
                            }
                        })
                    }

                } else {
                    setErrorMessage("ERROR : Insufficent Balance!!");
                }
            }



        }
    })

    return (
        <Container >
            <Grid container fluid >
                <Grid lg={12} md={12} sm={12} xs={12} style={{ display: 'flex', alignItems: 'cetner', justifyContent: 'center' }} >
                    <div className='widthDrawWrapper'>
                        <div className='withDrawContainer'>
                            <p className='withdrawTitle'>Withdraw</p>
                        </div>
                        <div style={{ paddingLeft: 16, paddingRight: 16 }}>
                            {
                                message ?
                                    <div className='successMessageContainer'>
                                        <p>Your order has been processed,<br></br>awaiting approval! Kindly check transaction page.</p>
                                    </div>
                                    :
                                    null
                            }
                        </div>
                        <div style={{ paddingLeft: 16, paddingRight: 16 }}>
                            {
                                errorMessage ?
                                    <div className='errorMessageContainer'>
                                        <p>{errorMessage}</p>
                                    </div>
                                    :
                                    null
                            }
                        </div>
                        <div className='formWrapper'>
                            <form onSubmit={formik.handleSubmit} className="formContainer" >
                                <Box className='myWalletContainer'>
                                    <p>Wallet Adress:</p>
                                    <TextField
                                        className='input'
                                        fullWidth
                                        disabled
                                        id="outlined-basic"
                                        label={cookie.get('wallet')}
                                        variant="outlined" />
                                </Box>
                                <Box className='toWalletContainer'>
                                    <p>Receiver:</p>
                                    <TextField
                                        className='input'
                                        fullWidth
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        value={formik.values.receiver}
                                        name="receiver"
                                        id="outlined-basic"
                                        label="Enter Wallet"
                                        variant="outlined" />
                                </Box>
                                <Box className='withdrawAmountContainer'>
                                    <p>Amount:</p>
                                    <div style={{ display: 'flex' }}>
                                        <TextField
                                            className='input'
                                            error={amountValue > user.balance}
                                            fullWidth
                                            // onBlur={updateInput}
                                            onChange={handleAmountChange}
                                            value={amountValue}
                                            // InputProps={{ inputProps: { min: 1, max: user.balance } }}
                                            name="amount"
                                            id="outlined-basic"
                                            type="number"
                                            label="Enter Amount"
                                            variant="outlined" />
                                        <Button
                                            onClick={() => setAmountValue(user.balance)}
                                            variant="contained"
                                            className='maxAmount'
                                        >
                                            MAX
                                        </Button>
                                    </div>
                                </Box>
                                {/* <Box className='withdrawCheckboxContainer'>
                                    <FormControlLabel
                                        control={<Checkbox />}
                                        label="Withdraw Now"
                                        checked={checked}
                                        onChange={handleChange} />
                                </Box> */}
                                <Box className='withdrawSendContainer'>
                                    {
                                        !((formik.values.receiver === "" && amountValue === "") || amountValue <= 0 || formik.values.receiver === "" || amountValue === "") ?
                                            <Button
                                                variant="contained"
                                                type="submit"
                                                style={{ width: '100%' }}
                                                className="withdrawSubmitButton">Withdraw</Button>
                                            :
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                className='disabledWithdrawSubmitButton'
                                                disabled>Withdraw</Button>
                                    }

                                </Box>
                            </form>
                        </div>
                    </div>
                </Grid>
            </Grid>

        </Container>
    )
}

export default WithDraw