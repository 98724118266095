import { Container, Grid } from '@mui/material'
import React from 'react'
import './style.css'
import Lottie from 'react-lottie';
import * as animationData from '../../assets/lottie/crypto-coins.json'


function MobileApp() {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };
    return (
        <>
            <div className='cryptocurrencyWrapper'>
                <Container fluid >
                    <Grid container fluid >
                        <Grid container fluid lg={4} md={6} sm={12} xs={12} sx={{ display: { lg: 'block', md: 'block', sm: 'block', xs: 'block' } }}>
                            <div className='cryptocurrencyLottieContainer'>
                                <div className='lottieFileCont'>
                                    <Lottie
                                        options={defaultOptions}
                                        className='lottieFile'
                                    />
                                </div>
                            </div>
                        </Grid>
                        <Grid container fluid lg={8} md={6} sm={12} xs={12}>
                            <div className='cryptocurrencyInfoWrapper'>
                                <div className='cryptocurrencyInfoContainer'>
                                    <h2 className='cryptocurrencyInfoTitle'>Cryptocurrency Intelligence</h2>
                                    <p className='cryptocurrencyInfoText'>
                                        Cryptocurrency is a digital or virtual currency that uses cryptography for security and operates independently of a central bank. 
                                        Cryptocurrencies are decentralized, meaning they are not subject to government or financial institution control, 
                                        and they use a public transaction database called a blockchain to record and verify all transactions. 
                                        The most well-known cryptocurrency is Bitcoin, which was created in 2009. 
                                        <br></br>
                                        <br></br>
                                        However, there are now thousands of cryptocurrencies in existence, 
                                        each with its unique features, intended use cases, and market value. 
                                        Cryptocurrencies have gained significant attention from investors and the general public due to their potential for high returns, 
                                        speculative trading, and their ability to operate across borders without the need for traditional banking infrastructure.
                                        <br></br>
                                        <br></br>
                                        While cryptocurrencies have the potential to revolutionize the financial industry, they also face several challenges, 
                                        including regulatory uncertainty, security issues, and high volatility. Despite these challenges, 
                                        cryptocurrencies are expected to continue to grow and evolve as technology and regulation develop, 
                                        and their impact on the global economy and financial system remains an ongoing topic of debate and research.
                                        <br></br>
                                        <br></br>
                                    </p>
                                    <h2 className='cryptocurrencyInfoTitle'>Most Popular</h2>
                                    <p className='cryptocurrencyInfoText'>
                                        Cryptocurrency is a digital or virtual currency that is secured using cryptography, 
                                        making it difficult to counterfeit or double-spend. It operates independently of a central bank and uses decentralized control, 
                                        meaning that transactions are recorded on a public ledger called a blockchain. 
                                        <br></br>
                                        <br></br>
                                        This ledger is maintained by a network of computers around the world, 
                                        making it impossible for any one entity to control the currency or the network. 
                                        Cryptocurrencies such as Bitcoin, Ethereum, and Litecoin have gained significant popularity in recent years 
                                        due to their potential as an alternative to traditional fiat currency and as a store of value. 
                                        Transactions are usually faster and cheaper than traditional methods of money transfer, 
                                        and the lack of intermediaries can result in lower fees. 
                                        <br></br>
                                        <br></br>
                                        However, cryptocurrencies can also be volatile and their value can fluctuate rapidly.
                                        Additionally, their decentralized nature has made them attractive to criminal elements who use them for illicit activities such as money laundering or purchasing illegal goods and services on the dark web. 
                                        Despite the risks and challenges, the rise of cryptocurrencies has led to increased interest and investment in blockchain technology, 
                                        which has potential applications beyond currency, such as in supply chain management, 
                                        voting systems, and identity verification.
                                        <br></br>
                                        <br></br>
                                    </p>
                                    <h2 className='cryptocurrencyInfoTitle'>Benefits</h2>
                                    <p className='cryptocurrencyInfoText'>
                                        With cryptocurrency, the transaction cost is low to nothing at all—unlike,
                                        for example, the fee for transferring money from a digital wallet to a bank account.
                                        You can make transactions at any time of the day or night, and there are no limits on purchases
                                        and withdrawals. And anyone is free to use cryptocurrency, unlike setting up a bank account,
                                        which requires documentation and other paperwork.
                                        <br></br>
                                        <br></br>
                                        International cryptocurrency transactions are faster than wire transfers too.
                                        Wire transfers take about half a day for the money to be moved from one place to another.
                                        With cryptocurrencies, transactions take only a matter of minutes or even seconds.
                                        <br></br>
                                        <br></br>
                                    </p>

                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </Container>
            </div>
        </>
    )
}

export default MobileApp