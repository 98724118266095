import { Button, Container, Grid } from '@mui/material'
import React, { useState } from 'react'
import './styles.css'
import Cookies from 'universal-cookie'
import { useFormik } from 'formik'
import { deposite } from '../../api'
import Snackbars from '../../components/snackbar'


function Deposit() {
    const cookie = new Cookies()
    const [successopen, setSuccessOpen] = useState(false);




    const formik = useFormik({
        initialValues: {
            wallet: '',
            amount: ''
        },
        onSubmit: () => {
            const wallet = cookie.get('wallet')
            const amount = formik.values.amount;
            const func = deposite(wallet, amount)
            func.then(async (response) => {
                if (response.success) {
                    setSuccessOpen(true)
                    window.location.reload()
                } else {
                    console.log("error");
                }
            })
        }
    })
    return (
        <div style={{ height: '70vh' }}>
            <Container>
                <Grid container fluid >
                    <Grid lg={12} md={12} sm={12} xs={12} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <div className='depositeWrapper'>
                            <div className='depositeContainer'>
                                <div className='depositeTitleContainer'>
                                    <p className='depositeTitle'>Deposit</p>
                                </div>
                                <form onSubmit={formik.handleSubmit} className="formContainer">
                                    <div style={{ padding: 16 }}>
                                        <p className='myWallet'>My Wallet:</p>
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <div>
                                                <input
                                                    className='inputDisabled'
                                                    disabled
                                                    size="small"
                                                    id="outlined-basic"
                                                    value={cookie.get('wallet')}
                                                    variant="outlined" />
                                            </div>
                                            <div className='copyButtonContainer'>
                                                <Button
                                                    variant="contained"
                                                    onClick={() => navigator.clipboard.writeText(cookie.get('wallet'))}
                                                    className="copyButton">
                                                    copy wallet
                                                </Button>
                                            </div>

                                        </div>
                                    </div>
                                    {/* <Box className='depositeAmountContainer'>
                                    <p>Amount:</p>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        value={formik.values.amount}
                                        name="amount"
                                        id="outlined-basic"
                                        type="number"
                                        label="Enter Amount"
                                        variant="outlined" />
                                </Box>
                                <div className='depositeCheckboxContainer'>
                                    <FormControlLabel
                                        control={<Checkbox />}
                                        label="Send Now"
                                        checked={checked}
                                        onChange={handleChange} />
                                </div>
                                <div className='depositeSendContainer'>
                                    {
                                        checked ?
                                            <Button type="submit" variant="contained" style={{ width: '100%' }} className="depositeSubmitButton">Send</Button>
                                            :
                                            <Button type="submit" variant="contained" style={{ width: '100%', borderRadius: 10 }} disabled >Send</Button>
                                    }

                                </div> */}
                                </form>
                            </div>
                        </div>
                    </Grid>
                </Grid>
                <Snackbars open={successopen} text="sent Successfully!" severity="success" />
            </Container>
        </div>
    )
}

export default Deposit