import * as React from 'react';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import './styles.css'
import moment from 'moment';
import { Container } from '@mui/system';
// import { useState } from 'react';
// import { useRef } from 'react';
// import { useEffect } from 'react';

export default function CoinTable({ coins }) {
    // const [query, setQuery] = useState("");
    // const focusDiv = useRef();

    // useEffect(() => {
    //     if (focusDiv.current) focusDiv.current.focus();
    // }, [focusDiv]);

    // const search = (data) => {
    //     return data.filter((item) => item.name.toLowerCase().includes(query));
    // }
    // const searchCoins = search(coins);

    return (
        <div className='coinTableWrapper'>
            <Container >
                {/* <div className='search'>
                    <input type="text" placeholder='search...' onChange={(e) => setQuery(e.target.value)} ref={focusDiv} />
                </div> */}
                <div className='coinTableContainer'>
                    {/* <TableContainer> */}
                    <div className='convertSentence'>
                        Convert cash into crypto, symply.
                    </div>
                    <table sx={{ minWidth: 650 }} aria-label="simple table" id="coinTable" className='coinTable'>
                        <TableHead>
                            <TableRow>
                                <TableCell className='cryptoTableCell'>Coin</TableCell>
                                <TableCell className='cryptoTableCell'>Price</TableCell>
                                <TableCell className='cryptoTableCell'>%Change</TableCell>
                                <TableCell className='cryptoTableCell'>24h Min</TableCell>
                                <TableCell className='cryptoTableCell'>24h Max</TableCell>
                                <TableCell className='cryptoTableCell'>Last Update</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {coins.map((item) => (
                                <TableRow
                                    key={item.name}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        <div className='cryptoMarketLogoName'>
                                            <div
                                                className='cryptoMarketLogo'
                                                style={{ backgroundImage: `url(${item.image})` }}
                                            ></div>
                                            <p className='cryptoMarketName'>{item.name}/<span>{item.symbol}</span></p>
                                        </div>
                                    </TableCell>
                                    <TableCell align="center" className='cryptoMarketName'>{item.current_price}$</TableCell>
                                    <TableCell align="center">
                                        <p
                                            className={item.price_change_percentage_24h < 0 ? 'negChange' : 'posChange'}
                                        >
                                            {item.price_change_percentage_24h}%
                                        </p>
                                    </TableCell>
                                    <TableCell align="center" className='cryptoMarketName'>{item.low_24h}$</TableCell>
                                    <TableCell align="center" className='cryptoMarketName'>{item.high_24h}$</TableCell>
                                    <TableCell align="center" className='cryptoMarketName'>{moment(item.last_updated).format('L , h:mm a')}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </table>
                    {/* </TableContainer> */}
                </div>
            </Container >
        </div>
    );
}
