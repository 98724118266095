import { Container, Grid } from '@mui/material'
import React from 'react'
import './styles.css'
import { RiSecurePaymentLine } from 'react-icons/ri'
import { IoNavigateSharp } from 'react-icons/io5'
import { IoNewspaperSharp } from 'react-icons/io5'

function Features() {

    return (
        <>
            <div className='featuresWrapper'>
                <Container fluid>
                    <Grid container fluid >
                        <Grid lg={12} md={12} sm={12} xs={12} >
                            <div className='featuresTitleContainer'>
                                <div className='featuresTitle'>
                                    <p>Services</p>
                                </div>
                                <div className='featuresSubTitle'>
                                    <p>Discover what we are offering you<br></br> as the best platform for trading</p>
                                </div>
                            </div>
                        </Grid>
                        <Grid lg={12} md={12} sm={12} xs={12}>
                            <div className='featuresContainer'>
                                <div className='featuresCardsContainer'>
                                    <div className='featuresCardIconContainer'>
                                        <RiSecurePaymentLine color='#FDA736' size={35} />
                                    </div>
                                    <div className='featuresCardTitleContainer'>
                                        <p>Cryptocurrency<br></br>wallet</p>
                                    </div>
                                    <div className='featuresCardTextContainer'>
                                        <p>A digital wallet that allows users to<br></br> securely store, send, and receive <br></br>various cryptocurrencies.</p>
                                    </div>

                                </div>
                                <div className='featuresCardsContainer'>
                                    <div className='featuresCardIconContainer'>
                                        <IoNavigateSharp color='#FDA736' size={35} />
                                    </div>
                                    <div className='featuresCardTitleContainer'>
                                        <p>Cryptocurrency <br></br>exchange</p>
                                    </div>
                                    <div className='featuresCardTextContainer'>
                                        <p>An online platform that enables users to buy, sell, and trade cryptocurrencies with<br></br> other users.</p>
                                    </div>
                                </div>
                                <div className='featuresCardsContainer'>
                                    <div className='featuresCardIconContainer'>
                                        <IoNewspaperSharp color='#FDA736' size={40} />
                                    </div>
                                    <div className='featuresCardTitleContainer'>
                                        <p>Cryptocurrency<br></br>payment gateway</p>
                                    </div>
                                    <div className='featuresCardTextContainer'>
                                        <p>A payment processing service that allows businesses<br></br> to accept payments in <br></br>cryptocurrencies.</p>
                                    </div>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </Container>
            </div>

        </>
    )
}

export default Features



// {
//     features.map((item) => (
//         <>
//             <Grid lg={4} md={6} sm={6} xs={12} className="featuresWrapper" >
//                 <div className='featuresContainer'>
//                     <p className='featureTitle'>{item.title}</p>
//                     <p className='featureSubTitle'>{item.subTitle}</p>
//                 </div>
//             </Grid>
//         </>
//     ))
// }
// <Grid lg={12} md={12} sm={12} xs={12}>
//     <div className='featuresContainerTwo'>
//         <p className='featuresTitleTwo'>Take control of your future with <br></br>superior technology and security</p>
//     </div>
// </Grid>
// {
//     features2.map((item) => (
//         <Grid lg={4} md={4} sm={4} xs={12}>
//             <div className='technologyContainer'>
//                 <img alt="featureImage" src={item.img} style={{ width: 100, aspectRatio: '1' }} />
//                 <h3 className='technologyTitle'>{item.title}</h3>
//                 <p className='technologySubTitle'>{item.subTitle}</p>
//             </div>
//         </Grid>
//     ))
// }